import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import IconDefs from 'static/home/icons/defs.svg';
import { connect } from 'react-redux';
import { actions, selectors } from './shared/redux/auth';
import { actions as cmsActions} from './shared/redux/cms';
import { selectors as uiSelectors, actions as uiActions, ModalTypes } from './shared/redux/ui';
import { Route, NotFoundRoute, Redirect, Switch, Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import 'react-owl-carousel2/lib/styles.css';
import './styles/main.scss';
import Pages from './pages/index.jsx';
import CookieAlert from './components/cookieAlert.jsx';
import Footer from './components/footer.jsx';
import Config from 'config.js';
import NavBar, { NavBarContext } from './components/navBar/index.jsx';
import RegistrationModal from "components/registrationModal/";
import LoginModal from "components/loginModal/index.jsx";
import ResetPasswordModal from "components/resetPasswordModal/resetPasswordModal.jsx";
import SetPasswordModal from "components/setPasswordModal/index.jsx";
import ContactModal from "components/contactModal/index.jsx";
import SupportModal from "components/supportModal/index.jsx";
import PrivateRoute from "components/privateRoute/index.jsx";
import Player, { PlayerContext } from 'components/player/index.jsx';
import RodoModal from "components/rodo-modal";
import MobileMenu from './components/mobileMenu/';
import Classnames from 'classnames';
import { DependencyContext } from 'shared/DependencyContext';
import Notifications from "components/notifications";
import { translate } from "react-i18next";

console.log('Config', Config.socketServer.host);

const history = createBrowserHistory();
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: { state: 'loading' },
      isCookieAlertVisible: true,
      language: props.i18n.language,
      navBarInstance: null,
      player: {
        instance: null,
      },
      forceDesktopControls: false,
      navBarMenuOpen: false,
      footerSectionOpen: false,
    };

    this.player = null; // EvrPlayer instance

    this.acceptCookies = this.acceptCookies.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.loginFacebook = this.loginFacebook.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleChangedPassword = this.handleChangedPassword.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
    this.handleSupportSubmit = this.handleSupportSubmit.bind(this);
    this.showCookies = this.showCookies.bind(this);
    this.onRodoSend = this.onRodoSend.bind(this);
    this.onToggleNavBarMenu = this.onToggleNavBarMenu.bind(this);
    this.openRegistrationModal = this.openRegistrationModal.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.openResetPasswordModal = this.openResetPasswordModal.bind(this);
    this.openSetPasswordModal = this.openSetPasswordModal.bind(this);
    this.openContactModal = this.openContactModal.bind(this);
    this.openSupportModal = this.openSupportModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setFooterSectionOpen = this.setFooterSectionOpen.bind(this);
    this.fetchUser = this.fetchUser.bind(this);

    if (Config.home.urlParams) {
      let urlSearch = window.location.search;
      if (urlSearch.indexOf('login') > -1) {
        this.openLoginModal();
      }
      if (urlSearch.indexOf('register') > -1) {
        this.openRegistrationModal();
      }
      if (urlSearch.indexOf('forgotPassword') > -1) {
        this.openResetPasswordModal();
      }
      if (urlSearch.indexOf('resetPassword') > -1) {
        this.openSetPasswordModal();
      }
      if (urlSearch.indexOf('support') > -1) {
        this.openSupportModal();
      }
    }
    this.props.fetchCurrentUser();

    history.listen(history => {
      this.closeModal;
      this.setFooterSectionOpen(false);
      if (history.hash === "") {
        window.scrollTo(0, 0)
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser.messageSent && nextProps.isContactModalOpened) {
      this.toggleContactModal();
      this.props.clearRequestsState();
    }
    if (nextProps.currentUser.resetLinkSent && nextProps.isResetPasswordOpened) {
      this.toggleResetPasswordModal();
    }
    if (nextProps.currentUser.state == 'loggedIn' && nextProps.modal.modalType === ModalTypes.Login) {
      this.closeModal();
    }
    if (nextProps.currentUser.state == 'signedUp' && nextProps.modal.modalType === ModalTypes.Registration) {
      this.notifications && this.notifications.add(this.props.t('registration.notification'));
      this.closeModal();
    }
    if (nextProps.currentUser.errorCode && nextProps.currentUser.errorCode === 'EMAIL_ALREADY_REGISTERED') {
      this.notifications && this.notifications.add(this.props.t('registration.emailAlreadyRegistered'));
    }
  }
  componentWillMount(){
    this.props.cmsInit({lang: /pl/ig.test(this.state.language) ? 'pl' : ''});
  }
  componentDidMount() {
    let urlPath = window.location.pathname;
    this.showCookies(urlPath);

    let playerInstance = this.player.getWrappedInstance();

    this.setState({
      player: {
        ...this.state.player,
        instance: playerInstance
      },
      notifications: this.notifications,
    });
    this.props.fetchOffers();
  }
  setFooterSectionOpen(open) {
    this.setState({
      footerSectionOpen: open
    })
  }
  showCookies(urlPath) {
    let pageWithoutCookieAlert = urlPath.indexOf('/explore') > -1 || urlPath.indexOf('/p') > -1 || urlPath.indexOf('/v') > -1 || urlPath.indexOf('/livePreview') > -1 || urlPath.indexOf('/embed') > -1;

    // HOTFIX: avoid iframe embed errors in IE and Edge
    if (pageWithoutCookieAlert) {
      return;
    }
    let isCookieAccepted = this.readCookie('cookies-accepted');
    let isIframe = (self.frameElement && (self.frameElement + "").indexOf("HTMLIFrameElement") > -1);

    let isCookieAlertVisible = !isCookieAccepted && !pageWithoutCookieAlert && !isIframe;

    this.setState({
      isCookieAlertVisible: isCookieAlertVisible
    });
  }
  handleLogin(e) {
    this.props.login(e);
  }
  handleLogout() {
    this.props.logout();
  }
  loginFacebook(e) {
    this.props.loginFacebook(e);
  }
  handleRegister(e) {
    this.props.register(e);
  }
  handleResetPassword(e) {
    this.props.sendResetPasswordLink(e);
  }
  handleChangedPassword(e) {
    this.props.sendChangedPassword(e);
  }
  handleContactSubmit(e) {
    this.props.sendMessage(e);
  }
  handleSupportSubmit(e) {
    this.props.sendMessage(e);
  }
  fetchUser() {
    this.props.fetchCurrentUser();
  }
  readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  acceptCookies() {
    let d = new Date();
    d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = "cookies-accepted=true;" + expires + ";path=/";
    this.setState({
      isCookieAlertVisible: true
    })
  }
  closeModal() {
    // reset modals data
    this.props.clearRequestsState();
    if (this.props.modal.modalType === ModalTypes.Registration) {
      this.props.clearAuthState();
    }
    this.props.hideModal();
  }
  openRegistrationModal() {
    this.props.showModal({
      modalType: ModalTypes.Registration,
    });
  }
  openLoginModal() {
    this.props.showModal({
      modalType: ModalTypes.Login,
    });
  }
  openResetPasswordModal() {
    this.props.showModal({
      modalType: ModalTypes.ResetPassword,
    });
  }
  openSetPasswordModal() {
    this.props.showModal({
      modalType: ModalTypes.SetPassword,
    });
  }
  openContactModal() {
    this.props.showModal({
      modalType: ModalTypes.Contact,
    });
  }
  openSupportModal() {
    this.props.showModal({
      modalType: ModalTypes.Support,
    });
  }
  onRodoSend() {
    this.props.acceptRodo();
  }
  onToggleNavBarMenu() {
    this.setState({
      navBarMenuOpen: !this.state.navBarMenuOpen,
    });
  }

  render() {
    const body = document.getElementsByTagName('body')[0];
    const redirectPage = window.location.pathname.includes('3dsredirect');
    body.onresize = () => {
      if (this.state.navBarMenuOpen && window.innerWidth > 1279) {
        this.setState({ navBarMenuOpen: false });
      }
    };

    const { currentUser, modal } = this.props;
    const { navBarMenuOpen, forceDesktopControls } = this.state;

    let routes = Config.home.routes && Config.home.routes.map(route => {
      let RouteComponent = route.private ? PrivateRoute : Route;

      if (!route.forLang || this.state.language.indexOf(route.forLang) > -1) {
        return (<RouteComponent user={this.props.currentUser} key={route.path || route.page} path={route.path} component={Pages[route.page]} exact={!!route.exact} />);
      }
    }),
      footerVisible = Config.home.ui.footerVisible && this.props.footerVisible && !(modal && modal.modalType === ModalTypes.ResetPassword) && !redirectPage,
      navBarVisible = !!Config.home.ui.navBarVisible && !(modal && modal.modalType === ModalTypes.ResetPassword) && !redirectPage,
      rodoModalVisible = currentUser && currentUser.state === 'loggedIn' && !currentUser.agreementsSeen,
      pageLinks = Config.home.ui.pageLinks || [],
      pageLinksVisible = !!Config.home.ui.pageLinksVisible,
      blurActive = !!(modal && modal.modalType && ModalTypes[modal.modalType]),
      isRegistrationOpened = modal && modal.modalType === ModalTypes.Registration,
      isLoginOpened = modal && modal.modalType === ModalTypes.Login,
      isResetPasswordOpened = modal && modal.modalType === ModalTypes.ResetPassword,
      isSetPasswordOpened = modal && modal.modalType === ModalTypes.SetPassword,
      isContactModalOpened = modal && modal.modalType === ModalTypes.Contact,
      isSupportModalOpened = modal && modal.modalType === ModalTypes.Support,
      pageContainerClassName = Classnames({
        'page-container': true,
        'page-container--active': navBarMenuOpen,
      });

    return (
      <DependencyContext.Provider value={{ player: this.state.player.instance, navBar: this.state.navBarInstance, notifications: this.state.notifications }}>
        <PlayerContext.Provider value={{ player: this.state.player }}>
          <NavBarContext.Provider value={this.state.navBarInstance}>
            <Router history={history}>
              <div>
                <IconDefs />
                <MobileMenu
                  open={navBarMenuOpen}
                  pageLinksVisible={pageLinksVisible}
                  pageLinks={pageLinks}
                  currentUser={this.props.currentUser}
                  onToggleMenu={this.onToggleNavBarMenu}
                  openLoginModal={this.openLoginModal}
                  openRegistrationModal={this.openRegistrationModal}
                />
                <div className={pageContainerClassName}>
                  {navBarVisible && (
                    <NavBar
                      visible={navBarVisible}
                      currentUser={this.props.currentUser}
                      openLoginModal={this.openLoginModal}
                      openRegistrationModal={this.openRegistrationModal}
                      handleLogout={this.handleLogout}
                      blur={blurActive}
                      wrappedComponentRef={navBar => {
                        let wrappedNavBar;

                        if (navBar) {
                          wrappedNavBar = navBar.getWrappedInstance();
                          if (wrappedNavBar && !this.state.navBarInstance) {
                            this.setState({
                              navBarInstance: wrappedNavBar,
                            });
                          }
                        }
                      }}
                      menuOpen={navBarMenuOpen}
                      onToggleMenu={this.onToggleNavBarMenu}
                      pageLinksVisible={pageLinksVisible}
                      pageLinks={pageLinks}
                    />
                  )}
                  <Notifications
                    ref={notifications => this.notifications = notifications}
                  />
                  <div className={Classnames(blurActive ? 'blur' : '', modal.modalType === 'Login' ? 'hidden' : '')}>
                    <Switch>
                      {routes}
                    </Switch>
                  </div>
                  <Player
                    ref={player => this.player = player}
                    presentation={this.props.presentation}
                    presentationMeta={this.props.presentationMeta}
                    rotateScene={true}
                    forceDesktopControls={forceDesktopControls} />
                  <RegistrationModal
                    open={isRegistrationOpened}
                    user={this.props.currentUser}
                    onSubmit={this.handleRegister}
                    onClose={this.closeModal}
                    socialButtons={true}
                    fetchUser={this.fetchUser}
                  />
                  {isLoginOpened && <LoginModal
                    open={isLoginOpened}
                    scrollPosition={this.props.scrollPosition}
                    user={this.props.currentUser}
                    onSubmit={this.handleLogin}
                    openResetPasswordModal={this.openResetPasswordModal}
                    onClose={this.closeModal}
                    socialButtons={true}
                    openRegistrationModal={this.openRegistrationModal}
                    fetchUser={this.fetchUser}
                  />}
                  <ResetPasswordModal
                    open={isResetPasswordOpened}
                    user={this.props.currentUser}
                    onSubmit={this.handleResetPassword}
                    onClose={this.closeModal} />
                  <SetPasswordModal
                    open={isSetPasswordOpened}
                    user={this.props.currentUser}
                    onSubmit={this.handleChangedPassword}
                    onClose={this.closeModal}
                  />
                  <ContactModal
                    open={isContactModalOpened}
                    messageSent={this.props.currentUser.messageSent}
                    onSubmit={this.handleContactSubmit}
                    onClose={this.closeModal}
                  />
                  <SupportModal
                    open={isSupportModalOpened}
                    messageSent={this.props.currentUser.messageSent}
                    onSubmit={this.handleSupportSubmit}
                    onClose={this.closeModal}
                  />
                  <RodoModal
                    open={rodoModalVisible}
                    onSend={this.onRodoSend}
                  />
                  <CookieAlert accepted={this.state.isCookieAlertVisible} handleAcceptCookie={this.acceptCookies} />
                  {footerVisible &&
                    <Footer
                      openLoginModal={this.openLoginModal}
                      footerSectionOpen={this.state.footerSectionOpen}
                      setFooterSectionOpen={this.setFooterSectionOpen}
                    />
                  }
                </div>
              </div>
            </Router>
          </NavBarContext.Provider>
        </PlayerContext.Provider>
      </DependencyContext.Provider>
    );
  }
}

const {
  getCurrentUser
} = selectors;

const {
  isFooterVisible,
  isRegistrationOpened,
  scrollPosition,
  isLoginOpened,
  isResetPasswordOpened,
  isSetPasswordOpened,
  isContactModalOpened,
  getModal,
} = uiSelectors;

const {
  fetchOffers,
  fetchCurrentUser,
  login,
  logout,
  register,
  sendResetPasswordLink,
  sendChangedPassword,
  sendMessage,
  clearRequestsState,
  clearAuthState,
  acceptRodo,
} = actions;

const {
  cmsInit
} = cmsActions;
const {
  showModal,
  hideModal,
} = uiActions;

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  footerVisible: isFooterVisible(state),
  scrollPosition: scrollPosition(state),
  modal: getModal(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchCurrentUser,
    login,
    logout,
    register,
    sendResetPasswordLink,
    sendChangedPassword,
    sendMessage,
    clearRequestsState,
    clearAuthState,
    showModal,
    hideModal,
    acceptRodo,
    fetchOffers,
    cmsInit
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate('translations')(Main));

