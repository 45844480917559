import React, { Component } from 'react';
import './index.scss';
import { translate } from 'react-i18next';
import Close from 'components/close';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import AssetHelper from 'AssetHelper';
import CopyIcon from 'static/home/icons/content_copy.svg';
import Snackbar from 'components/snackbar/snackbar';
import ClassNames from "classnames";
import StartCoViewingModal from "../startCoViewingModal";
import i18next from "i18next";

const langPl = i18next.language.includes('pl');

class VRModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSnackbarOpen: false,
      notificationMessage: '',
      copied: this.props.t('common.copied')
    };
    this.handleCopy = this.handleCopy.bind(this);
    this.handleSnackbarOpen = this.handleSnackbarOpen.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  }
  handleSnackbarOpen(notificationMessage) {
    this.setState({
      isSnackbarOpen: true,
      notificationMessage,
    });
  }
  handleSnackbarClose() {
    this.setState({
      isSnackbarOpen: false,
      notificationMessage: '',
    });
  }
  handleCopy(el) {
    const { copied } = this.state;
    el.select();
    document.execCommand('copy');
    // this.props.onSnackbarOpen(copied);
    this.handleSnackbarOpen(copied);
  }
  render () {
    let shareUrl;
    if (this.props && this.props.shareUrl && this.props.shareUrl.includes('embed')) {
      shareUrl = this.props.shareUrl.replace('embed', 'p');
    } else {
      shareUrl = this.props.shareUrl;
    }
    let { t, opened } = this.props;

    const google = AssetHelper.staticPath('/static/home/Quest2Lower.png');

    return <div className={"webvr_QR_modal" + (opened?' visible':'')}>
      <Close onClick={() => this.props.requestClose && this.props.requestClose()}/>
      <div className="content">
        <div className="title">
          {t('VRModal.title')}
        </div>
        <img src={google} className="vr-image"/>
        <div className="text">
          {t('VRModal.openOnVR')}<br />
        </div>
        <div className="link-container">
          <input
            className="link"
            ref={(link) => this.link = link}
            value={shareUrl}
          />
          <CopyIcon style={{ cursor: 'pointer', position: 'absolute', top: 34, right: 13 }} onClick={() => this.handleCopy(this.link)} />
        </div>
        <div className="text more">
          <a href={langPl ? 'https://evryplace.com/pl/knowledge-base/wirtualna-rzeczywistosc/' : 'https://evryplace.com/knowledge-base/virtual-reality/'} target="_blank">{t('VRModal.more')}</a>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.isSnackbarOpen}
        message={this.state.notificationMessage}
        autoHideDuration={4000}
        onClose={this.handleSnackbarClose}
      />
    </div>;
  }
}

export default translate('translations')(VRModal);
